<!-- 头部 -->
<template>
  <div class="header-wrap">
    <div class="head-box">
      <div class="header_left" @click="goHome"><img src="../../assets/images/logo.svg" alt="" /></div>
      <div class="header_right">
        <el-dropdown trigger="click" @command="handleCommand">
          <span class="head_login_after">
            <img :src="info.userImage || '../../assets/images/user.svg'" alt="" />
          </span>
          <el-dropdown-menu slot="dropdown" class="head_login_menu">
            <el-dropdown-item command="accountInfo">账号信息</el-dropdown-item>
            <el-dropdown-item command="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
import { clearAllCookie } from "@/utils/utils.js"
export default {
  data: function() {
    return {
      info: {},
    }
  },
  created() {
    this.getInfo()
  },
  mounted() {
    // 接收批量上传接口上传成功后返回的值
    this.$bus.$on("login_Success", () => {
      this.getInfo()
    });
  },
  methods: {
    getInfo() {
      try {
        const loginInfo = JSON.parse(localStorage.getItem('loginInfo'))
        const { sFaceUrl, sPhoneNum, sUserName } = loginInfo.data.stAccountInfo;
        this.info = {
          userName: sUserName || '',
          userPhoneNum: sPhoneNum || '',
          userImage: sFaceUrl || ''
        }
      } catch (error) {
        console.log(error)
      }
    },
    goHome() {
      this.$router.push({ path: "/home" });
    },
    //菜单命令
    handleCommand(command) {
      if (command === 'logout') {
        this.$confirm('是否退出登陆，退出后请重新扫码登陆！', '退出提示', {
          confirmButtonText: '是',
          cancelButtonText: '否',
          type: 'info'
        }).then(() => {
          localStorage.clear()
          clearAllCookie()
          this.$router.push({ path: "/homePage" });
        }).catch(() => {
          console.log('取消退出登陆')         
        });
      }
      if (command === 'accountInfo') {
        this.$router.push({ path: "/accountInfo" });
      }
    },
  },
};
</script>
<style scoped lang="less">
.header-wrap {
  width: 100%;
  background: #fff;
  box-shadow: 0px 3px 6px rgba(219, 224, 234, 0.3);
  position: relative;
  z-index: 10;
  position: fixed;
}
.head-box {
  height: 60px;
  padding: 0 18px;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header_left {
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    height: 36px;
  }
  h3 {
    color: #FF7500;
    font-size: 28px;
  }
}
.header_right {
  cursor: pointer;
  .head_login_after {
    display: flex;
    width: 36px;
    height: 36px;
    justify-content: center;
    align-items: center;
    background: #9093A0;
    overflow: hidden;
    border-radius: 50%;
    > img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}
</style>
