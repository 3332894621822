<!-- 脚部 -->
<template>
  <div class='foot-wrap'>
    Copyright © 2021.灯塔财经 All rights reserved.鄂ICP备17015359号-2
  </div>
</template>

<script>

export default {
  filters: {},
  components: {},
  data() {
  return {

  };
  },
  computed: {},
  watch: {},
  methods: {

  },
  created() {

  },
  mounted() {

  },
}
</script>
<style scoped>
.foot-wrap {
  width: 100%;
  height: 100%;
  font-size: 16px;
  line-height: 150px;
  text-align: center;
  color: #fff;
}
</style>